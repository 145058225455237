// © Copyright IBM Corp. 2022, 2025

import * as React from 'react';

import { CreateBase, EditBase, List, regex, SimpleForm, useListContext, useRedirect, useRefresh, useUnique } from 'react-admin';

import { Typography } from '@mui/material';

import { MyBreadcrumbs } from '../component/breadcrumb';
import { BulkActionModal } from '../component/BulkActionModal';
import { CustomToolbar } from '../component/customComponents';
import { MyEdit } from '../component/MyEdit';
import { CarbonTextInputField } from '../component/MyInputs';
import { MyTable } from '../component/MyTable';
import { NotificationContext } from '../context/notificationContext';
import { useBatchDeleteAction } from '../hooks/useDelete';
import { useHandleError } from '../hooks/useHandleError';

const headers = [
  { key: 'id', header: 'ID' },
  { key: 'first_name', header: 'First Name' },
  { key: 'last_name', header: 'Last Name' },
  { key: 'email', header: 'Email' },
  { key: 'dateJoined', header: 'Date Joined' },
  { key: 'edit', header: 'Edit' },
];

const Table = () => {
  const redirect = useRedirect();
  const { data, isPending } = useListContext();
  const [rowsToDelete, setRowsToDelete] = React.useState();
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const { batchDeleteAction } = useBatchDeleteAction();

  if (isPending || !data) return null;

  const clone = structuredClone(data);

  const confirmBulkDelete = (rows) => {
    setRowsToDelete(rows.map((r) => r.id));
    setConfirmationOpen(true);
  };

  clone?.forEach((result, idx) => {
    result.edit = <MyEdit url={`/entity/${result.id}`} key={`edit-${idx}`} />;
    result.dateJoined = <Typography variant="body2"> {new Date(result.date_joined).toLocaleString()}</Typography>;
  });
  return (
    <>
      <BulkActionModal
        confirmationOpen={confirmationOpen}
        title="Are you sure you want to delete these Entities?"
        body={rowsToDelete?.map((r, i) => (
          <li key={`r-${i}`}>{r}</li>
        ))}
        batchActionClick={() =>
          batchDeleteAction({
            resource: 'entity',
            rowsToDelete: rowsToDelete,
            setRowsToDelete: setRowsToDelete,
            setConfirmationOpen: setConfirmationOpen,
            msg: 'Entity',
          })
        }
        setConfirmationOpen={setConfirmationOpen}
        setRowsToDelete={setRowsToDelete}
      />
      <MyTable
        resource="entity"
        data={clone}
        headers={headers}
        showActions={true}
        zebra={false}
        addRow={() => redirect('create', 'entity')}
        bulkAction={confirmBulkDelete}
      />
    </>
  );
};

export const EntityList = () => (
  <>
    <MyBreadcrumbs path={'/entity'} />
    <List perPage={10} sort={{ field: 'name', order: 'ASC' }} actions={false} pagination={false}>
      <Table />
    </List>
  </>
);

export const EntityCreate = (props) => {
  const notificationCtx = React.useContext(NotificationContext);
  const { handleError } = useHandleError();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    redirect('/entity');
    refresh();
    notificationCtx.add({ msg: 'Entity created', status: 'success' });
  };

  const onError = (err) => {
    handleError({ err: err?.body?.details || {}, resource: 'entity', create: true });
  };

  return (
    <CreateBase {...props} mutationOptions={{ onSuccess, onError }}>
      <MyBreadcrumbs path="/entity" />
      <EntityForm
        {...props}
        redirect="list"
        defaultValues={{
          first_name: '',
          last_name: '',
          email: '',
        }}
      />
    </CreateBase>
  );
};

export const EntityForm = ({ props, isEdit }) => {
  const unique = useUnique();
  return (
    <SimpleForm
      disableInvalidFormNotification
      {...props}
      toolbar={<CustomToolbar showDelete={isEdit ? true : false} redirectPath="/entity" resource="entity" />}
    >
      <CarbonTextInputField source="first_name" label="First Name" isRequired />
      <CarbonTextInputField source="last_name" label="Last Name" isRequired/>
      <CarbonTextInputField source="email" label="Email" isRequired />
    </SimpleForm>
  );
};

export const EntityEdit = (props) => {
  const notificationCtx = React.useContext(NotificationContext);
  const { handleError } = useHandleError();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    redirect('/entity');
    refresh();
    notificationCtx.add({ msg: 'Changes saved', status: 'success' });
  };

  const onError = (err) => {
    handleError({ err: err?.body?.details || {}, resource: 'entity' });
  };

  return (
    <EditBase {...props} mutationMode="pessimistic" mutationOptions={{ onSuccess, onError }}>
      <MyBreadcrumbs path="/entity" />
      <EntityForm isEdit={true} />
    </EditBase>
  );
};
