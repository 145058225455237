// © Copyright IBM Corp. 2022, 2025

import * as React from 'react';

import {
  BooleanField,
  BooleanInput,
  Button,
  ChipField,
  Create,
  CreateBase,
  EditBase,
  List,
  ReferenceArrayField,
  ReferenceInput,
  regex,
  required,
  SimpleForm,
  SingleFieldList,
  useGetList,
  useListContext,
  useRecordContext,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { useParams } from 'react-router-dom';

import { Checkmark, MisuseOutline } from '@carbon/icons-react';
import { Column, Grid } from '@carbon/react';

import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import { Typography } from '@mui/material';

import { MyBreadcrumbs } from '../component/breadcrumb';
import { BulkActionModal } from '../component/BulkActionModal';
import { CustomToolbar } from '../component/customComponents';
import { JsonEditor2 } from '../component/jsonEditor2';
import { MyEdit } from '../component/MyEdit';
import { CarbonTextInputField, MySelect } from '../component/MyInputs';
import { MyTable } from '../component/MyTable';
import { NotificationContext } from '../context/notificationContext';
import { useBatchDeleteAction } from '../hooks/useDelete';
import { useHandleError } from '../hooks/useHandleError';
import { copyToClipboard } from '../utils/utils';

const headers = [
  { key: 'id', header: 'ID' },
  { key: 'name', header: 'Name' },
  { key: 'isActive', header: 'Is Active' },
  { key: 'certificate_authorities', header: 'Certificate Authorities' },
  { key: 'dateCreated', header: 'Date Created' },
  { key: 'clients', header: 'Clients' },
  { key: 'edit', header: 'Edit' },
];

const Table = () => {
  const redirect = useRedirect();
  const { data, isPending } = useListContext();
  const [rowsToDelete, setRowsToDelete] = React.useState();
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const { batchDeleteAction } = useBatchDeleteAction();
  const [clone, setClone] = React.useState(null);

  React.useEffect(() => {
    const processData = async () => {
      if (!clone && data) {
        const temp = structuredClone(data);

        temp?.forEach((result, idx) => {
          result.edit = <MyEdit url={`/environment/${result.id}`} key={`edit-${idx}`} />;
          result.dateCreated = <Typography variant="body2"> {new Date(result.created_at).toLocaleString()}</Typography>;
          result.isActive = result.is_active ? <Checkmark /> : <MisuseOutline />;
          result.clients = <ClientShowButton label="Clients" recordId={result.id} />;
          result.certificate_authorities = (
            <ReferenceArrayField label="Certificate Authorities" reference="ca" source="client_cas" record={result}>
              <SingleFieldList>
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
          );
        });

        setClone(temp);
      }
    };

    processData();
  });

  if (isPending || !data) return null;

  const confirmBulkDelete = (rows) => {
    setRowsToDelete(rows.map((r) => r.id));
    setConfirmationOpen(true);
  };

  return (
    <>
      <BulkActionModal
        confirmationOpen={confirmationOpen}
        title="Are you sure you want to delete these Environments?"
        body={rowsToDelete?.map((r, i) => (
          <li key={`r-${i}`}>{r}</li>
        ))}
        batchActionClick={() =>
          batchDeleteAction({
            resource: 'environment',
            rowsToDelete: rowsToDelete,
            setRowsToDelete: setRowsToDelete,
            setConfirmationOpen: setConfirmationOpen,
            msg: 'Environment',
          })
        }
        setConfirmationOpen={setConfirmationOpen}
        setRowsToDelete={setRowsToDelete}
      />
      <MyTable
        resource="environments"
        data={clone}
        headers={headers}
        showActions={true}
        zebra={false}
        addRow={() => redirect('create', 'environment')}
        bulkAction={confirmBulkDelete}
      />
    </>
  );
};

export const EnvironmentCreate = (props) => {
  const notificationCtx = React.useContext(NotificationContext);
  const { handleError } = useHandleError();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    redirect('/environment');
    refresh();
    notificationCtx.add({ msg: 'Environment created.', status: 'success' });
  };

  const onError = (err) => {
    handleError({ err: err?.body?.details || {}, resource: 'environment', create: true });
  };

  return (
    <CreateBase>
      <MyBreadcrumbs path={'/environment'} />
      <Create {...props} mutationOptions={{ onSuccess, onError }}>
        <EnvironmentForm
          {...props}
          redirect="list"
          defaultValues={{
            is_active: true,
            name: '',
            description: '',
          }}
        />
      </Create>
    </CreateBase>
  );
};

export const EnvironmentForm = ({ edit, ...props }) => {
  const record = useRecordContext();

  if (!record && edit) return null;

  return (
    <SimpleForm
      disableInvalidFormNotification
      {...props}
      toolbar={<CustomToolbar showDelete={edit ? true : false} redirectPath={'/environment'} resource="environment" />}
    >
      <CarbonTextInputField
        label="Name *"
        source="name"
        validate={[
          required('User Name is required'),
          regex(/^[a-zA-Z0-9_\-@.+]+$/, 'Enter a valid username. This value may contain only letters, numbers, and  . + - _  characters.'),
        ]}
      />
      <CarbonTextInputField label="Description" source="description" />
      <BooleanInput source="is_active" />
      <div style={{ width: '100%' }}>
        <ReferenceInput source="policy_chain" reference="policy_chain" sort={{ field: 'id', order: 'ASC' }}>
          <div style={{ width: '30%' }}>
            <MySelect label="Policy Chain" source="policy_chain" showEmpty />
          </div>
        </ReferenceInput>
      </div>
      <JsonEditor2 {...props} source="meta" rootName="Meta" />
      {edit && <ClientShowButton recordId={record.id} />}
    </SimpleForm>
  );
};

export const EnvironmentEdit = (props) => {
  const notificationCtx = React.useContext(NotificationContext);
  const { handleError } = useHandleError();
  const onSuccess = () => {
    notificationCtx.add({ msg: 'Environment updated successfully', status: 'success' });
  };

  const onError = (err) => {
    handleError({ err: err?.body?.details || {}, resource: 'environment' });
  };

  return (
    <EditBase {...props} mutationMode="pessimistic" mutationOptions={{ onSuccess, onError }}>
      <MyBreadcrumbs path={'/environment'} />
      <EnvironmentForm edit={true} />
    </EditBase>
  );
};

const ClientShowButton = ({ recordId }) => {
  const redirect = useRedirect();
  const handleClick = (event) => {
    redirect(`/environment/${recordId}/client/`);
    event.stopPropagation();
  };
  return (
    <Button onClick={handleClick} label="Show Clients">
      <DevicesOtherIcon />
    </Button>
  );
};

export const EnvironmentList = () => {
  return (
    <>
      <MyBreadcrumbs path={'/environment'} />
      <List perPage={10} sort={{ field: 'name', order: 'ASC' }} actions={false} pagination={false} hasCreate>
        <Table />
      </List>
    </>
  );
};

const CAClientAutocomplete = () => {
  const { id } = useParams();
  const record = useRecordContext();
  const { data, isLoading, error, refetch } = useGetList(`environment/${id}/certificate/available`, {
    filter: {},
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'name', order: 'DESC' },
  });

  React.useEffect(() => {
    const refetchCAs = () => {
      refetch();
    };
    refetchCAs();
  }, [record]);

  if (data === undefined) return null;

  const clone = structuredClone(data);

  const includeCurrent = (available, current) => {
    if (current?.certificate) {
      available.push({ id: current.certificate, name: current.cert_name });
    }

    // fix double rendering of certs
    const uniqueCerts = [...new Map(available.map((item) => [item.id, item])).values()]
      .map((item) => ({ id: item.id, name: item.name }))
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        } else {
          return 0;
        }
      });

    return uniqueCerts;
  }; //adds all the current fingerprints to all the available fingerprints

  if (error) {
    return <p>ERROR</p>;
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div style={{ width: '25%' }}>
      <MySelect label="CA Certs" source="certificate" choices={clone ? includeCurrent(clone, record) : []} showEmpty />
    </div>
  );
};

export const EnvironmentClientForm = ({ edit, ...props }) => {
  const notificationCtx = React.useContext(NotificationContext);
  const record = useRecordContext();

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    copyToClipboard(event.target.textContent)
      ? notificationCtx.add({ msg: 'text copied to clipboard', status: 'success' })
      : notificationCtx.add({ msg: 'failed to copy text', status: 'error' });
  };

  return (
    <SimpleForm disableInvalidFormNotification {...props}>
      <CarbonTextInputField
        source="name"
        label="Name"
        validate={[
          required('User Name is required'),
          regex(/^[a-zA-Z0-9_\-@.+]+$/, 'Enter a valid username. This value may contain only letters, numbers, and  . + - _  characters.'),
        ]}
      />
      {edit && <BooleanField source="bound" />}
      <BooleanInput source="allow_dynamic_binding" />
      <BooleanInput defaultValue={true} source="enable_doh_listener" />
      <BooleanInput defaultValue={true} source="enable_dot_listener" />
      <Grid condensed style={{ paddingLeft: '0', marginBottom: '5px' }}>
        <Column lg={16}>
          <Grid style={{ borderBottom: 'solid .5px', lineHeight: '2.5em', marginTop: '5px' }}>
            <Column lg={2}>
              <b>Protocol</b>
            </Column>
            <Column lg={8}>
              <b>URL</b>
            </Column>
            <Column lg={6}>
              <b>Token</b>
            </Column>
          </Grid>
          {record?.listeners.map((l, i) => {
            return (
              <Grid key={i} style={{ borderBottom: 'solid .5px', marginTop: '10px' }}>
                <Column lg={2} style={{ paddingTop: '25%' }} onClick={handleClick}>
                  {l.protocol}
                </Column>
                <Column lg={8} style={{ wordBreak: 'break-all', paddingBottom: '15px' }} onClick={handleClick}>
                  {l.url}
                </Column>
                <Column lg={6} style={{ wordBreak: 'break-all', paddingBottom: '15px' }} onClick={handleClick}>
                  {l.token}
                </Column>
              </Grid>
            );
          })}
        </Column>
      </Grid>
      <JsonEditor2 {...props} source="meta" rootName="Meta" />
      <CAClientAutocomplete />
    </SimpleForm>
  );
};

const clientHeaders = [
  {
    key: 'id',
    header: 'ID',
  },
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'listeners',
    header: 'Listeners',
  },
  {
    key: 'dateCreated',
    header: 'Date Created',
  },
  { key: 'edit', header: 'Edit' },
];

const ClientTable = ({ id }) => {
  const redirect = useRedirect();
  const { data, isPending } = useListContext();
  const [rowsToDelete, setRowsToDelete] = React.useState();
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const { batchDeleteAction } = useBatchDeleteAction();
  const notificationCtx = React.useContext(NotificationContext);

  if (isPending || !data) return null;

  const clone = structuredClone(data);

  const confirmBulkDelete = (rows) => {
    setRowsToDelete(rows.map((r) => r.id));
    setConfirmationOpen(true);
  };

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    copyToClipboard(event.target.textContent)
      ? notificationCtx.add({ msg: 'text copied to clipboard', status: 'success' })
      : notificationCtx.add({ msg: 'failed to copy text', status: 'error' });
  };

  clone?.forEach((result, idx) => {
    result.listeners = (
      <Grid condensed style={{ paddingLeft: '0', marginBottom: '5px' }}>
        <Column lg={16}>
          <Grid style={{ borderBottom: 'solid .5px', lineHeight: '2.5em', marginTop: '5px' }}>
            <Column lg={2}>
              <b>Protocol</b>
            </Column>
            <Column lg={8}>
              <b>URL</b>
            </Column>
            <Column lg={6}>
              <b>Token</b>
            </Column>
          </Grid>
          {result?.listeners?.map((l, i) => {
            return (
              <Grid key={i} style={{ borderBottom: 'solid .5px', marginTop: '10px' }}>
                <Column lg={2} style={{ paddingTop: '25%' }} onClick={handleClick}>
                  {l.protocol}
                </Column>
                <Column lg={8} style={{ wordBreak: 'break-all', paddingBottom: '15px' }} onClick={handleClick}>
                  {l.url}
                </Column>
                <Column lg={6} style={{ wordBreak: 'break-all', paddingBottom: '15px' }} onClick={handleClick}>
                  {l.token}
                </Column>
              </Grid>
            );
          })}
        </Column>
      </Grid>
    );
    result.dateCreated = <Typography variant="body2"> {new Date(result.created_at).toLocaleString()}</Typography>;
    result.edit = <MyEdit url={`/environment/${id}/client/${result.id}`} key={`edit-${idx}`} />;
  });

  return (
    <>
      <BulkActionModal
        confirmationOpen={confirmationOpen}
        title="Are you sure you want to delete these Environment Clients?"
        body={rowsToDelete?.map((r, i) => (
          <li key={`r-${i}`}>{r}</li>
        ))}
        batchActionClick={() =>
          batchDeleteAction({
            resource: `environment/${id}/client`,
            rowsToDelete: rowsToDelete,
            setRowsToDelete: setRowsToDelete,
            setConfirmationOpen: setConfirmationOpen,
            msg: 'Environment Client',
          })
        }
        setConfirmationOpen={setConfirmationOpen}
        setRowsToDelete={setRowsToDelete}
      />
      <MyTable
        resource="environments"
        data={clone}
        headers={clientHeaders}
        showActions={true}
        zebra={false}
        addRow={() => redirect('create', `environment/${id}/client/`)}
        bulkAction={confirmBulkDelete}
      />
    </>
  );
};

export const EnvironmentClientList = () => {
  const { id } = useParams();

  return (
    <>
      <MyBreadcrumbs path={`/environment/${id}/client`} />
      <List resource={`environment/${id}/client`} sort={{ field: 'name', order: 'ASC' }} actions={false} pagination={false} hasCreate>
        <ClientTable id={id} />
      </List>
    </>
  );
};

export const EnvironmentClientCreate = () => {
  const { id } = useParams();
  const notificationCtx = React.useContext(NotificationContext);
  const { handleError } = useHandleError();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const client = `environment/${id}/client`;

  const onSuccess = () => {
    redirect(`/environment/${id}/client`);
    refresh();
    notificationCtx.add({ msg: 'Environment client created.', status: 'success' });
  };

  const onError = (err) => {
    handleError({ err: err?.body?.details || {}, resource: client, create: true });
  };

  return (
    <CreateBase resource={client} mutationOptions={{ onSuccess, onError }}>
      <MyBreadcrumbs path={`/${client}`} />
      <EnvironmentClientForm toolbar={<CustomToolbar showDelete={false} redirectPath={client} resource={client} />}></EnvironmentClientForm>
    </CreateBase>
  );
};

export const EnvironmentClientEdit = () => {
  const { id, clientId } = useParams();
  const notificationCtx = React.useContext(NotificationContext);
  const { handleError } = useHandleError();

  const client = `environment/${id}/client`;

  const onSuccess = () => {
    notificationCtx.add({ msg: 'Environment client updated.', status: 'success' });
  };

  const onError = (err) => {
    handleError({ err: err?.body?.details || {}, resource: client });
  };

  return (
    <EditBase resource={client} id={clientId} mutationMode="pessimistic" mutationOptions={{ onSuccess, onError }}>
      <MyBreadcrumbs path={`/${client}`} />
      <EnvironmentClientForm
        edit={true}
        toolbar={<CustomToolbar redirectPath={client} showDelete={true} resource={`environment/${id}/client`} />}
      ></EnvironmentClientForm>
    </EditBase>
  );
};
