// © Copyright IBM Corp. 2022, 2025

import * as React from 'react';

import { useGetList } from 'react-admin';

import { DonutChart, LineChart, SimpleBarChart } from '@carbon/charts-react';
import { Column, Dropdown, FlexGrid, Heading, Row, Section } from '@carbon/react';

import DnsIcon from '@mui/icons-material/Dns';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import PeopleIcon from '@mui/icons-material/People';
import TrafficIcon from '@mui/icons-material/Traffic';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Card } from '@mui/material';

import { MyBreadcrumbs } from '../component/breadcrumb';
import { CardWithLink } from '../component/customComponents';

import { fetchJsonWithCookie } from '../authProvider';
import config from '../config';

export const Metrics = () => {
  const { data: policies, total: policies_total } = useGetList('policy');
  const { total: policy_chains } = useGetList('policy_chain');
  const { total: domains } = useGetList('domainlist');
  const { total: ca } = useGetList('ca');
  const { data: environments, total: environment } = useGetList('environment');
  const { total: entities } = useGetList('entity');
  const { total: idp } = useGetList('idp');
  const { total: accesstokens } = useGetList('accesstoken');
  const defaultEnvironments = [{ id: "all", name: "All environments" }];
  const [selectedEnvironment, setSelectedEnvironment] = React.useState(defaultEnvironments[0]);
  const [policiesMetrics, setPoliciesMetrics] = React.useState([]);
  const [domainsTopKMetrics, setDomainsTopKMetrics] = React.useState([]);
  const [actionsMetrics, setActionsMetrics] = React.useState([]);
  const [actionsSumMetrics, setActionsSumMetrics] = React.useState([]);

  React.useEffect(() => {
    const nowDate = new Date();
    const oneHourAgo = new Date(nowDate.setHours(nowDate.getHours() - 1));
    const environmentFilter = selectedEnvironment.id === "all" ? "" : `&environment_id=${selectedEnvironment.id}`;
    const timestampFilter = `&timestamp_gte=${oneHourAgo.toISOString()}`;
    const pageSize = "&page_size=1200";
    const metricsQuery = `?${environmentFilter}${timestampFilter}${pageSize}`;

    fetchJsonWithCookie(`${config.apiUrl}/v1/reports/metrics/policies/${metricsQuery}`)
      .then(({ json }) => {
        setPoliciesMetrics(json.results.map(metric => {
          const relatedPolicy = policies?.find(p => p.id === metric.policy_id);
          metric.policy_name = relatedPolicy?.name || metric.policy_id;
          return metric;
        }));
      });

    fetchJsonWithCookie(`${config.apiUrl}/v1/reports/metrics/actions/${metricsQuery}`)
      .then(({ json }) => {
        setActionsMetrics(json.results.map(metric => {
          const relatedEnvironment = environments?.find(e => e.id === metric.environment_id);
          metric.environment_name = relatedEnvironment?.name || metric.environment_id;
          return metric;
        }));
      });

    fetchJsonWithCookie(`${config.apiUrl}/v1/reports/metrics/actions/sum/${metricsQuery}`)
      .then(({ json }) => {
        const result = [
          {
            group: "Allow",
            value: 0
          },
          {
            group: "Deny",
            value: 0
          },
          {
            group: "Forward",
            value: 0
          },
          {
            group: "Static Answer",
            value: 0
          },
          {
            group: "NS1 Answer",
            value: 0
          },
          {
            group: "Private Authority",
            value: 0
          },
          {
            group: "Intercept",
            value: 0
          }
        ]
        json.results.forEach(element => {
          result[0].value += element.allow;
          result[1].value += element.deny;
          result[2].value += element.forward;
          result[3].value += element.static_answer;
          result[4].value += element.ns1_answer;
          result[5].value += element.private_authority;
          result[6].value += element.intercept;
        });
        setActionsSumMetrics(result);
      });

    fetchJsonWithCookie(`${config.apiUrl}/v1/reports/metrics/domains/topk/${metricsQuery}`)
      .then(({ json }) => {
        const topDomains = {};
        json.results.forEach(metric => {
          metric.top_domains.forEach(topDomain => {
            topDomains[topDomain.domain_name] = topDomains[topDomain.domain_name] ? topDomains[topDomain.domain_name] + topDomain.count : topDomain.count;
          });
        });

        const sortedTopDomains = Object.entries(topDomains).sort((a, b) => b[1] - a[1]);

        const result = sortedTopDomains.map(element => {
          return { domain_name: element[0], count: element[1] };
        });
        setDomainsTopKMetrics(result.slice(0, 5));
      });
  }, [selectedEnvironment, environments, policies]);

  if (!policies || !environments) return null;

  return (
    <>
      <MyBreadcrumbs path="/metrics" />
      <Card sx={{ paddingLeft: '5px', backgroundColor: 'unset', paddingBottom: '5px', boxShadow: 'unset', marginBottom: '15px' }}>
        <FlexGrid fullWidth>
          <Row>
            <Section level={5}>
              <Heading>ZNS</Heading>
            </Section>
          </Row>
          <Row narrow>
            <Column lg={3} style={{ margin: '5px' }}>
              <CardWithLink to="#/ca" icon={VerifiedIcon} title={'Total CAs'} subtitle={ca} />
            </Column>
            <Column lg={3} style={{ margin: '5px' }}>
              <CardWithLink to="#/policy" icon={TrafficIcon} title={'Total Policies'} subtitle={policies_total} />
            </Column>
            <Column lg={3} style={{ margin: '5px' }}>
              <CardWithLink to="#/policy_chain" icon={TrafficIcon} title={'Total Policy Chains'} subtitle={policy_chains} />
            </Column>
            <Column lg={3} style={{ margin: '5px' }}>
              <CardWithLink to="#/environment" icon={MultipleStopIcon} title={'Total Environments'} subtitle={environment} />
            </Column>
            <Column lg={3} style={{ margin: '5px' }}>
              <CardWithLink to="#/domainlist" icon={DnsIcon} title={'Total Domains'} subtitle={domains} />
            </Column>
          </Row>

          <Row>
            <Section level={5}>
              <Heading>Identities</Heading>
            </Section>
          </Row>
          <Row narrow>
            <Column lg={3} style={{ margin: '5px' }}>
              <CardWithLink to="#/entity" icon={PeopleIcon} title={'Total Entities'} subtitle={entities} />
            </Column>
            <Column lg={3} style={{ margin: '5px' }}>
              <CardWithLink to="#/idp" icon={FolderSharedIcon} title={'Total Identity Providers'} subtitle={idp} />
            </Column>
          </Row>

          <Row>
            <Section level={5}>
              <Heading>Other</Heading>
            </Section>
          </Row>
          <Row narrow>
            <Column lg={3} style={{ margin: '5px' }}>
              <CardWithLink to="#/accesstoken" icon={PeopleIcon} title={'Total Access Tokens'} subtitle={accesstokens} />
            </Column>
          </Row>
          <hr />
          <Dropdown
            id="environment-dropdown"
            label="Select an environment"
            titleText="Environment metrics"
            helperText="The metrics below relate to this environment"
            onChange={(e) => setSelectedEnvironment(e.selectedItem)}
            items={environments ? defaultEnvironments.concat(...environments) : defaultEnvironments}
            itemToString={item => item ? item.name : ''}
            selectedItem={selectedEnvironment}
          ></Dropdown>
          <Row>
            <Column style={{ margin: '5px' }}>
              <Card sx={{ padding: '5px', minWidth: 400 }}>
                <LineChart
                  data={policiesMetrics}
                  options={{
                    title: policiesMetrics.length ? 'QPS' : 'QPS (no data)',
                    data: {
                      groupMapsTo: 'policy_name'
                    },
                    tooltip: {
                      showTotal: false
                    },
                    axes: {
                      bottom: {
                        scaleType: 'time',
                        mapsTo: 'timestamp',
                        ticks: {
                          rotation: 'always',
                        },
                      },
                      left: {
                        mapsTo: 'rate',
                      },
                    }
                  }}
                ></LineChart>
              </Card>
            </Column>
            <Column style={{ margin: '5px' }}>
              <Card sx={{ padding: '5px', minWidth: 400 }}>
                <LineChart
                  data={actionsMetrics}
                  options={{
                    title: actionsMetrics.length ? 'Domains Blocked' : 'Domains Blocked (no data)',
                    data: {
                      groupMapsTo: 'environment_name',
                    },
                    color: {
                      scale: {
                        'Count by day': 'orange',
                      },
                    },
                    axes: {
                      bottom: {
                        scaleType: 'time',
                        mapsTo: 'timestamp',
                        ticks: {
                          rotation: 'always',
                        },
                      },
                      left: {
                        mapsTo: 'deny'
                      },
                    },
                    legend: {
                      clickable: false,
                    },
                    tooltip: {
                      showTotal: false
                    },
                  }}
                ></LineChart>
              </Card>
            </Column>
            <Column style={{ margin: '5px' }}>
              <Card sx={{ padding: '5px', minWidth: 400 }}>
                <LineChart
                  data={actionsMetrics}
                  options={{
                    title: actionsMetrics.length ? 'Domains Intercepted' : 'Domains Intercepted (no data)',
                    data: {
                      groupMapsTo: 'environment_name',
                    },
                    color: {
                      scale: {
                        Redirects: 'green',
                      },
                    },
                    axes: {
                      bottom: {
                        scaleType: 'time',
                        mapsTo: 'timestamp',
                        ticks: {
                          rotation: 'always',
                        }
                      },
                      left: {
                        mapsTo: 'intercept',
                      },
                    },
                    legend: {
                      clickable: false
                    },
                    tooltip: {
                      showTotal: false
                    },
                  }}
                ></LineChart>
              </Card>
            </Column>
          </Row>
          <Row>
            <Column style={{ margin: '5px' }}>
              <Card sx={{ padding: '5px', minWidth: 400 }}>
                <SimpleBarChart
                  data={domainsTopKMetrics}
                  options={{
                    title: domainsTopKMetrics.length ? 'Top 5 domains' : 'Top 5 domains (no data)',
                    data: {
                      groupMapsTo: 'domain_name'
                    },
                    axes: {
                      left: {
                        mapsTo: 'domain_name',
                        scaleType: 'labels'
                      },
                      bottom: {
                        mapsTo: 'count'
                      }
                    },
                    height: '400px'
                  }}
                ></ SimpleBarChart>
              </Card>
            </Column>
            <Column style={{ margin: '5px' }}>
              <Card sx={{ padding: '5px', minWidth: 400 }}>
                <DonutChart
                  data={actionsSumMetrics}
                  options={{
                    title: 'Action Types',
                    resizable: true,
                    donut: {
                      center: {
                        label: 'Count of Action Types',
                      },
                    },
                    height: '400px',
                  }}
                ></DonutChart>
              </Card>
            </Column>
          </Row>
        </FlexGrid>
      </Card>
    </>
  );
};
