// © Copyright IBM Corp. 2022, 2025

import * as React from 'react';
import { useEffect, useState } from 'react';

import { useLogin } from 'react-admin';
import { FaGithub, FaOpenid } from 'react-icons/fa';
import { SiAuth0 } from 'react-icons/si';

import { ArrowRight } from '@carbon/icons-react';
import {
  Button,
  Column,
  Form,
  Grid,
  Header,
  HeaderContainer,
  HeaderGlobalBar,
  HeaderName,
  Loading,
  SkipToContent,
  Stack,
  TextInput,
  Theme,
} from '@carbon/react';

import { Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import config from '../config';
import { NotificationContext } from '../context/notificationContext';

const componentsMap = new Map([
  ['github', FaGithub],
  ['Auth0', SiAuth0],
  ['oidc', FaOpenid],
]);

const APILogin = () => {
  const [apitoken, setApiToken] = React.useState();
  const [doLogin, setDoLogin] = React.useState(false);
  const login = useLogin();

  React.useEffect(() => {
    if (doLogin) {
      login();
    }
  }, [doLogin]);

  return (
    <Column lg={16} md={8} sm={4}>
      <Divider />
      <Typography sx={{ marginTop: '10px', marginBottom: '10px' }}>Use your API token</Typography>
      <TextInput
        id="apitoken"
        labelText=""
        onChange={(e) => {
          setApiToken(e.target.value);
        }}
      />
      <Button
        onClick={() => {
          sessionStorage.setItem('apiToken', apitoken);
          setDoLogin(true);
        }}
      >
        Set Token and Login
      </Button>
    </Column>
  );
};

const LoginResolver = () => {
  const [loading, setLoading] = useState(false);
  const [providers, setProviders] = useState([]);
  const [org, setOrg] = useState('');
  const [invalidOrg, setInvalidOrg] = useState(false);
  const [loginURL, setLoginURL] = useState('');
  const notificationCtx = React.useContext(NotificationContext);

  const [useAPIToken, setUseAPIToken] = React.useState(false);

  const keyLog = {};
  const handleKeyboard = ({ type, key, repeat, shiftKey, ctrlKey }) => {
    if (repeat) return;

    if (type === 'keydown') {
      keyLog[key] = true;
      // When control + shift + L are pressed.
      if (shiftKey && ctrlKey && key === 'L') {
        setUseAPIToken(true);
      }
    }

    // Remove the key from the log on keyup.
    if (type === 'keyup') delete keyLog[key];
  };

  React.useEffect(() => {
    const events = ['keydown', 'keyup'];
    events.forEach((name) => document.addEventListener(name, handleKeyboard));

    return () => events.forEach((name) => document.removeEventListener(name, handleKeyboard));
  });

  useEffect(() => {
    async function tryLogin() {
      if (loginURL) {
        setLoading(true);
        let next = '';
        if (window.location.toString().includes('next=')) {
          next = window.location.toString().split('next=')[1];
        }
        window.location.href = loginURL + `?next=${window.location.origin}/${next}`;
      }
    }
    tryLogin();
  }, [loginURL]);

  useEffect(() => {
    window.onpageshow = function (event) {
      sessionStorage.clear();
      if (event.persisted) {
        setLoading(false);
        setOrg('');
        setLoginURL('');
        setProviders([]);
      }
    };
  }, []);

  const getProviders = () => {
    setLoading('active');
    fetch(`${config.apiUrl}/v1/auth/begin/${org}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 404) {
          setProviders([]);
          throw { orgId: 'Provided Org Has No Configured Providers' };
        }
        return res.json();
      })
      .then(
        (result) => {
          result.auth_providers.sort((a, b) => a.display_name.localeCompare(b.display_name));
          setProviders(result.auth_providers);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          if (error.message?.includes('Failed to fetch')) {
            notificationCtx.add({ msg: 'Failed to connect to API', status: 'error' });
          }
          for (const [k, v] of Object.entries(error)) {
            notificationCtx.add({ msg: `${k}: ${v}`, status: 'error' });
            if (k === 'orgId') setInvalidOrg('Provided Org Has No Configured Providers');
          }
        },
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const onKeyDownHandler = (e) => {
    if (e.key === 'Enter') getProviders();
  };

  return (
    <>
      {loading && loginURL && <Loading withOverlay={true} />}
      <Grid spacing={2} style={{ height: '100vh', marginTop: '50px' }} fullWidth>
        <Column sm={4} md={4} lg={4}>
          <Stack gap={6}>
            <div style={{ marginLeft: '1em' }}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                onKeyDown={onKeyDownHandler}
              >
                <Grid style={{ paddingLeft: '0px' }}>
                  <Column lg={16} md={8} sm={4}>
                    <div className="no-account">Enter in your Organization ID</div>
                  </Column>
                  <Column lg={4} md={4} sm={4}>
                    <TextInput
                      ref={(input) => !useAPIToken && input && input.focus && input.focus()}
                      labelText="Organization ID"
                      placeholder="my-org"
                      id="org"
                      onBlur={(v) => {
                        setOrg(v.target.value);
                        setInvalidOrg('');
                      }}
                      onChange={(v) => {
                        setOrg(v.target.value);
                        setInvalidOrg('');
                      }}
                      invalid={!!invalidOrg}
                      invalidText={invalidOrg}
                      value={org}
                    />
                    <Button className="login" style={{ marginBottom: '15px' }} type="button" onClick={getProviders} disabled={!org}>
                      Get Login Providers
                      {loading && org && !loginURL ? <Loading className={'some-class'} withOverlay={false} small={true} /> : <ArrowRight className="arrow" />}
                    </Button>
                  </Column>

                  {Object.values(providers).map((provider) => (
                    <Column lg={16} md={8} sm={4} key={provider.display_name}>
                      <div key={provider.display_name}>
                        <Button
                          onClick={() => {
                            setLoginURL(provider.url);
                          }}
                          renderIcon={componentsMap.get(provider.icon)}
                          style={{ marginBottom: '5px', width: '100%' }}
                        >
                          Sign in with {provider.display_name}
                        </Button>
                      </div>
                    </Column>
                  ))}
                  {useAPIToken && <APILogin />}
                </Grid>
              </Form>
            </div>
          </Stack>
        </Column>
        <Column>
          <Box component="img" src="/background.png" alt="background image" width={800} height={800} />
        </Column>
      </Grid>
    </>
  );
};

const LoginPage = () => {
  return (
    <Theme theme="g10">
      <HeaderContainer
        render={() => (
          <>
            <Header aria-label="Zero Trust Connect">
              <SkipToContent />

              <HeaderName href="/" prefix="IBM">
                [Zero Trust Connect]
              </HeaderName>
              <HeaderGlobalBar />
            </Header>
            <LoginResolver />
          </>
        )}
      />
    </Theme>
  );
};

export default LoginPage;
