// © Copyright IBM Corp. 2022, 2025

import React from 'react';

import { useController } from 'react-hook-form';

import { JsonEditor } from 'json-edit-react';

import JSON5 from 'json5';

export const JsonEditor2 = (props) => {
  const { field } = useController({ name: props.source });

  const updateData = (e) => {
    field.onChange(e.newData);
  };
  return (
    <JsonEditor
      data={field.value || {}}
      defaultValue={''}
      rootName={props.rootName || ''}
      showArrayIndices={false}
      showCollectionCount={'when-closed'}
      onUpdate={updateData}
      enableClipboard={false}
      jsonParse={JSON5.parse}
    />
  );
};
